var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container finishedOrder" },
    [
      !_vm.isShowEdit
        ? _c("div", { staticClass: "part" }, [
            _c("div", { staticClass: "actions_part clearfix" }, [
              _c("div", { staticClass: "actions_wrap" }, [
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [
                      _vm._v("生产订单编号：")
                    ]),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10",
                      attrs: { placeholder: "请输入" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchHandle($event)
                        }
                      },
                      model: {
                        value: _vm.productionOrderCode,
                        callback: function($$v) {
                          _vm.productionOrderCode =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "productionOrderCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [
                      _vm._v("生产物料编号：")
                    ]),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10",
                      attrs: { placeholder: "请输入" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchHandle($event)
                        }
                      },
                      model: {
                        value: _vm.productionMaterialCode,
                        callback: function($$v) {
                          _vm.productionMaterialCode =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "productionMaterialCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("生产类型：")]),
                    _c(
                      "el-select",
                      {
                        staticClass: "select_single w200 mr10",
                        attrs: { placeholder: "请选择" },
                        on: { change: _vm.searchHandle },
                        model: {
                          value: _vm.productionOrderType,
                          callback: function($$v) {
                            _vm.productionOrderType =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "productionOrderType"
                        }
                      },
                      [
                        _c("el-option", {
                          key: "",
                          attrs: { label: "全部", value: "" }
                        }),
                        _vm._l(_vm.materialClassList, function(item) {
                          return _c("el-option", {
                            key: item.index,
                            attrs: { label: item.name, value: item.name }
                          })
                        })
                      ],
                      2
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("创建日期：")]),
                    _c("el-date-picker", {
                      staticClass: "mr10",
                      attrs: {
                        "unlink-panels": "",
                        type: "daterange",
                        format: "yyyy-MM-dd",
                        "value-format": "yyyy-MM-dd",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期"
                      },
                      on: { change: _vm.dateValChange },
                      model: {
                        value: _vm.dateVal,
                        callback: function($$v) {
                          _vm.dateVal =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "dateVal"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "actions_btn_wrap down t_right" },
                [
                  _c("el-button", {
                    attrs: {
                      size: "small",
                      type: "primary",
                      icon: "el-icon-search",
                      circle: "",
                      title: "搜索"
                    },
                    on: { click: _vm.searchHandle }
                  }),
                  _c("el-button", {
                    attrs: {
                      size: "small",
                      type: "primary",
                      icon: "el-icon-plus",
                      circle: "",
                      title: "添加"
                    },
                    on: { click: _vm.addHandle }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "table_part clearfix" },
              [
                _c(
                  "el-tabs",
                  {
                    staticClass: "subnav_wrap",
                    on: { "tab-click": _vm.switchTabHandle },
                    model: {
                      value: _vm.activeName,
                      callback: function($$v) {
                        _vm.activeName = $$v
                      },
                      expression: "activeName"
                    }
                  },
                  _vm._l(_vm.subNavList, function(item) {
                    return _c(
                      "el-tab-pane",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.tableLoading,
                            expression: "tableLoading"
                          }
                        ],
                        key: item.value,
                        attrs: { label: item.label, name: item.value }
                      },
                      [
                        _c(
                          "el-table",
                          {
                            ref: "multipleTable",
                            refInFor: true,
                            staticClass: "table_list",
                            attrs: {
                              data: _vm.produceList,
                              "row-key": "id",
                              "expand-row-keys": _vm.expands,
                              border: "",
                              fit: "",
                              stripe: ""
                            },
                            on: {
                              "row-click": _vm.rowClick,
                              "expand-change": _vm.rowClick
                            }
                          },
                          [
                            _c("el-table-column", {
                              attrs: { type: "expand" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "actions_wrap mb20 clearfix"
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticClass: "fr mr10",
                                                attrs: { type: "primary" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.childExcelDownload(
                                                      scope.row
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("导出Excel")]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-table",
                                          {
                                            ref:
                                              "childMultipleTable_" +
                                              scope.row.id,
                                            refInFor: true,
                                            staticClass: "table_list",
                                            attrs: {
                                              data: scope.row.sampleStoriList,
                                              "row-key": "sampleUuid",
                                              "expand-row-keys":
                                                _vm.childExpands,
                                              border: "",
                                              fit: ""
                                            },
                                            on: {
                                              "row-click": _vm.childRowClick,
                                              "expand-change": _vm.childRowClick
                                            }
                                          },
                                          [
                                            _c("el-table-column", {
                                              attrs: { type: "expand" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(scope) {
                                                      return [
                                                        _c(
                                                          "el-table",
                                                          {
                                                            staticClass:
                                                              "table_list",
                                                            attrs: {
                                                              data:
                                                                scope.row
                                                                  .sampleList,
                                                              border: "",
                                                              fit: ""
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "el-table-column",
                                                              {
                                                                attrs: {
                                                                  fixed: "",
                                                                  label: "行号"
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "default",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var row =
                                                                          ref.row
                                                                        return [
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                row.sn
                                                                              ) +
                                                                              "\n                          "
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              }
                                                            ),
                                                            _c(
                                                              "el-table-column",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    "原材料物料编号"
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "default",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var row =
                                                                          ref.row
                                                                        return [
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                row.sourceMaterialCode
                                                                              ) +
                                                                              "\n                          "
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              }
                                                            ),
                                                            _c(
                                                              "el-table-column",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    "原材料类型"
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "default",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var row =
                                                                          ref.row
                                                                        return [
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                row.materialTypeName
                                                                              ) +
                                                                              "\n                          "
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              }
                                                            ),
                                                            _c(
                                                              "el-table-column",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    "原材料消耗数量"
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "default",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var row =
                                                                          ref.row
                                                                        return [
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                row.sourceQuantity
                                                                              ) +
                                                                              "\n                          "
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              }
                                                            ),
                                                            _c(
                                                              "el-table-column",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    "原材料已发货数量"
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "default",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var row =
                                                                          ref.row
                                                                        return [
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                row.sourceDeliveredNum
                                                                              ) +
                                                                              "\n                          "
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              }
                                                            ),
                                                            _c(
                                                              "el-table-column",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    "供应商"
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "default",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var row =
                                                                          ref.row
                                                                        return [
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                row.supplierShortName
                                                                              ) +
                                                                              "\n                            "
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              }
                                                            ),
                                                            _c(
                                                              "el-table-column",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    "出库仓位"
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "default",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var row =
                                                                          ref.row
                                                                        return [
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                row.outVirtualInventoryName
                                                                              ) +
                                                                              "\n                          "
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              }
                                                            ),
                                                            _c(
                                                              "el-table-column",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    "出库库位"
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "default",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var row =
                                                                          ref.row
                                                                        return [
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                row.outSubInventoryName
                                                                              ) +
                                                                              "\n                          "
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("el-table-column", {
                                              attrs: { label: "行号" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              row.productionSn
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("el-table-column", {
                                              attrs: { label: "期望交期" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              row.requiredDate
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("el-table-column", {
                                              attrs: { label: "生产物料编号" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              row.productionMaterialCode
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("el-table-column", {
                                              attrs: { label: "生产数量" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              row.productionQuantity
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("el-table-column", {
                                              attrs: { label: "入库仓位" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              row.inVirtualInventoryName
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("el-table-column", {
                                              attrs: { label: "入库库位" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              row.inSubInventoryName
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("el-table-column", {
                                              attrs: { label: "已入库数" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              row.inventoryInNum
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("el-table-column", {
                                              attrs: { label: "备注" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(row.remark) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "生产订单编号" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(row.productionOrderCode) +
                                            "\n              "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "生产类型" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(row.productionType) +
                                            "\n              "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "用途" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(row.purpose) +
                                            "\n              "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "创建时间" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(row.createTime) +
                                            "\n              "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "申请人" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(row.applicantName) +
                                            "\n              "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "凭证状态" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [
                                        _c(
                                          "el-tag",
                                          {
                                            staticClass: "pointer",
                                            attrs: {
                                              slot: "reference",
                                              type: _vm._f("statusFilter")(
                                                row.stStatus
                                              )
                                            },
                                            slot: "reference"
                                          },
                                          [_vm._v(_vm._s(row.stStatusName))]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "操作" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      var $index = ref.$index
                                      return [
                                        row.stStatus == "Draft"
                                          ? _c(
                                              "div",
                                              [
                                                _c("el-button", {
                                                  staticClass: "el-icon-edit",
                                                  attrs: {
                                                    type: "primary",
                                                    circle: "",
                                                    size: "mini",
                                                    title: "编辑"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.editHandle(
                                                        $index,
                                                        row
                                                      )
                                                    }
                                                  }
                                                }),
                                                _c("el-button", {
                                                  staticClass:
                                                    "el-icon-copy-document",
                                                  attrs: {
                                                    type: "primary",
                                                    circle: "",
                                                    size: "mini",
                                                    title: "克隆"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.cloneHandle(
                                                        $index,
                                                        row
                                                      )
                                                    }
                                                  }
                                                }),
                                                _c("el-button", {
                                                  staticClass: "el-icon-delete",
                                                  attrs: {
                                                    type: "danger",
                                                    circle: "",
                                                    size: "mini",
                                                    title: "删除"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.deleteHandle(
                                                        $index,
                                                        row
                                                      )
                                                    }
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        row.stStatus == "Approved"
                                          ? _c(
                                              "div",
                                              [
                                                _c("el-button", {
                                                  staticClass:
                                                    "el-icon-switch-button",
                                                  attrs: {
                                                    type: "danger",
                                                    circle: "",
                                                    size: "mini",
                                                    title: "关闭",
                                                    disabled:
                                                      row.closeStatus == "2"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.closeHandle(
                                                        $index,
                                                        row
                                                      )
                                                    }
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  1
                ),
                _c(
                  "div",
                  { staticClass: "pagination_wrap fr" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        "current-page": _vm.currentPage,
                        "page-sizes": [10, 50],
                        "page-size": _vm.pageSize,
                        layout: "sizes, total, prev, pager, next",
                        total: _vm.total
                      },
                      on: {
                        "size-change": _vm.handleSizeChange,
                        "current-change": _vm.handleCurrentChange,
                        "update:currentPage": function($event) {
                          _vm.currentPage = $event
                        },
                        "update:current-page": function($event) {
                          _vm.currentPage = $event
                        }
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _c(
            "div",
            { staticClass: "edit_part" },
            [
              _c(
                "div",
                { staticClass: "clearfix mb10" },
                [
                  _c(
                    "div",
                    { staticClass: "display_ib mr10" },
                    [
                      _vm._m(0),
                      _vm.isShowVal
                        ? _c("span", { staticClass: "tag" }, [
                            _vm._v(_vm._s(_vm.productionType))
                          ])
                        : _c(
                            "el-select",
                            {
                              staticClass: "select_single w200 mr10 mb10",
                              attrs: { placeholder: "请选择" },
                              on: { change: _vm.productionTypeChange },
                              model: {
                                value: _vm.productionType,
                                callback: function($$v) {
                                  _vm.productionType =
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                },
                                expression: "productionType"
                              }
                            },
                            [
                              _c("el-option", {
                                key: "",
                                attrs: { label: "请选择", value: "" }
                              }),
                              _vm._l(_vm.materialClassList, function(item) {
                                return _c("el-option", {
                                  key: item.index,
                                  attrs: { label: item.name, value: item.name }
                                })
                              })
                            ],
                            2
                          )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "display_ib mr10" },
                    [
                      _vm._m(1),
                      _vm.isShowVal
                        ? _c("span", { staticClass: "tag" }, [
                            _vm._v(_vm._s(_vm.purpose))
                          ])
                        : _c(
                            "el-select",
                            {
                              staticClass: "select_single w200 mr10 mb10",
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.purpose,
                                callback: function($$v) {
                                  _vm.purpose =
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                },
                                expression: "purpose"
                              }
                            },
                            [
                              _c("el-option", {
                                key: "",
                                attrs: { label: "请选择", value: "" }
                              }),
                              _vm._l(_vm.purposeTypeList, function(item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: { label: item.name, value: item.value }
                                })
                              })
                            ],
                            2
                          )
                    ],
                    1
                  ),
                  _vm.purpose === "客用"
                    ? _c(
                        "div",
                        { staticClass: "display_ib mr10" },
                        [
                          _vm.isShowVal
                            ? _c("div", [
                                _vm._m(2),
                                _c("span", { staticClass: "tag" }, [
                                  _vm._v(_vm._s(_vm.customerCode))
                                ])
                              ])
                            : _c("el-input", {
                                staticClass: "input_single w200 mr10 mb10",
                                attrs: { placeholder: "销售订单编号" },
                                model: {
                                  value: _vm.customerCode,
                                  callback: function($$v) {
                                    _vm.customerCode =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "customerCode"
                                }
                              })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("el-button", {
                    staticClass: "fr",
                    attrs: {
                      size: "small",
                      type: "primary",
                      icon: "el-icon-plus",
                      circle: "",
                      title: "添加"
                    },
                    on: { click: _vm.addEditHandle }
                  })
                ],
                1
              ),
              _c("upload-excel-component", {
                staticClass: "mb20",
                attrs: {
                  "on-success": _vm.importExcelSuccess,
                  "before-upload": _vm.importExcelBeforeUpload
                }
              }),
              _c(
                "div",
                { staticClass: "edit_wrap" },
                [
                  _c("editDoubleTable", {
                    attrs: {
                      tableList: _vm.editList,
                      tableLeaderFieldsList: _vm.leaderFieldsList,
                      tableTailFieldsList: _vm.tailFieldsList,
                      tableFieldsList: _vm.editFieldsList,
                      stStatus: _vm.stStatus
                    },
                    on: {
                      inputChange: _vm.inputChange,
                      selectChange: _vm.selectChange,
                      selectVisibleChange: _vm.selectVisibleChange,
                      datePickerChange: _vm.datePickerChange,
                      numberInputChange: _vm.numberInputChange,
                      deleteChlidHandle: _vm.deleteEditItemHandle,
                      addHandle: _vm.addEditItemHandle
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "mt20 mb20 fr" },
                    [
                      _c("el-button", { on: { click: _vm.cancelEditHandle } }, [
                        _vm._v("取消")
                      ]),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "preventReClick",
                              rawName: "v-preventReClick"
                            }
                          ],
                          attrs: { type: "primary" },
                          on: { click: _vm.saveEditHandle }
                        },
                        [_vm._v("保存")]
                      ),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "preventReClick",
                              rawName: "v-preventReClick"
                            }
                          ],
                          attrs: { type: "primary" },
                          on: { click: _vm.submitEditHandle }
                        },
                        [_vm._v("确认")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
      _c(
        "div",
        { staticClass: "popup_part" },
        [
          _c(
            "el-dialog",
            {
              directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
              attrs: {
                title: _vm.popupTitle,
                "close-on-click-modal": false,
                "close-on-press-escape": false,
                visible: _vm.dialogFormVisible
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogFormVisible = $event
                }
              }
            },
            [
              _c("el-tabs", [
                _c(
                  "ul",
                  { staticClass: "childlist" },
                  _vm._l(_vm.allFieldList, function(item, index) {
                    return _c("li", { key: index, class: item.class }, [
                      _vm._v(_vm._s(item.name))
                    ])
                  }),
                  0
                ),
                _c(
                  "div",
                  { staticClass: "allparentlist" },
                  _vm._l(_vm.checkProduceList.sampleStoriList, function(
                    childItem,
                    index
                  ) {
                    return _c(
                      "div",
                      { key: index, staticClass: "list_group" },
                      _vm._l(childItem.sampleList, function(
                        simpleItem,
                        simpleIndex
                      ) {
                        return _c(
                          "ul",
                          { key: simpleIndex, staticClass: "childcontentlist" },
                          [
                            _c(
                              "li",
                              {
                                staticClass: "w6p",
                                attrs: { title: simpleItem.sourceMaterialCode }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    simpleItem.sourceMaterialCode
                                      ? simpleItem.sourceMaterialCode
                                      : ""
                                  )
                                )
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "w6p",
                                attrs: { title: simpleItem.materialType }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    simpleItem.materialType
                                      ? simpleItem.materialType
                                      : ""
                                  )
                                )
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "w6p",
                                attrs: { title: simpleItem.sourceQuantity }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    simpleItem.sourceQuantity
                                      ? simpleItem.sourceQuantity
                                      : ""
                                  )
                                )
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "w6p",
                                attrs: { title: simpleItem.supplierName }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    simpleItem.supplierName
                                      ? simpleItem.supplierName
                                      : ""
                                  )
                                )
                              ]
                            ),
                            simpleIndex === 0
                              ? _c(
                                  "li",
                                  {
                                    staticClass: "w6p",
                                    attrs: { title: childItem.requiredDate }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          childItem.requiredDate
                                            ? childItem.requiredDate
                                            : ""
                                        ) +
                                        "\n              "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            simpleIndex === 0
                              ? _c(
                                  "li",
                                  {
                                    staticClass: "w6p",
                                    attrs: {
                                      title: childItem.productionMaterialCode
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          childItem.productionMaterialCode
                                            ? childItem.productionMaterialCode
                                            : ""
                                        ) +
                                        "\n              "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            simpleIndex === 0
                              ? _c(
                                  "li",
                                  {
                                    staticClass: "w6p",
                                    attrs: {
                                      title: childItem.productionQuantity
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          childItem.productionQuantity
                                            ? childItem.productionQuantity
                                            : ""
                                        ) +
                                        "\n              "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            simpleIndex !== 0
                              ? _c("li", { staticClass: "w6p" })
                              : _vm._e(),
                            simpleIndex !== 0
                              ? _c("li", { staticClass: "w6p" })
                              : _vm._e(),
                            simpleIndex !== 0
                              ? _c("li", { staticClass: "w6p" })
                              : _vm._e(),
                            _c(
                              "li",
                              {
                                staticClass: "w6p",
                                attrs: {
                                  title: simpleItem.outVirtualInventoryName
                                }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      simpleItem.outVirtualInventoryName
                                        ? simpleItem.outVirtualInventoryName
                                        : ""
                                    ) +
                                    "\n              "
                                )
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "w6p",
                                attrs: { title: simpleItem.outSubInventoryName }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      simpleItem.outSubInventoryName
                                        ? simpleItem.outSubInventoryName
                                        : ""
                                    ) +
                                    "\n              "
                                )
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "w6p",
                                attrs: {
                                  title: simpleItem.inVirtualInventoryName
                                }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      simpleItem.inVirtualInventoryName
                                        ? simpleItem.inVirtualInventoryName
                                        : ""
                                    ) +
                                    "\n              "
                                )
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "w6p",
                                attrs: { title: simpleItem.inSubInventoryName }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    simpleItem.inSubInventoryName
                                      ? simpleItem.inSubInventoryName
                                      : ""
                                  )
                                )
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "w6p",
                                attrs: { title: simpleItem.inventoryInNum }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    simpleItem.inventoryInNum
                                      ? simpleItem.inventoryInNum
                                      : ""
                                  )
                                )
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "w6p",
                                attrs: { title: simpleItem.confirmNo }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    simpleItem.confirmNo
                                      ? simpleItem.confirmNo
                                      : ""
                                  )
                                )
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "w6p",
                                attrs: { title: simpleItem.remark }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    simpleItem.remark ? simpleItem.remark : ""
                                  )
                                )
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass: "w6p",
                                attrs: { title: simpleItem.auditOption }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    simpleItem.auditOption
                                      ? simpleItem.auditOption
                                      : ""
                                  )
                                )
                              ]
                            )
                          ]
                        )
                      }),
                      0
                    )
                  }),
                  0
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "提示",
            visible: _vm.deleteDialogVisible,
            "close-on-click-modal": false,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.deleteDialogVisible = $event
            }
          }
        },
        [
          _c("span", [_vm._v("确定删除？")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.deleteDialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmDeleteHandle }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "提示",
            visible: _vm.submitDialogVisible,
            "close-on-click-modal": false,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.submitDialogVisible = $event
            }
          }
        },
        [
          _c("span", [_vm._v("确定提交审批？")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.submitDialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmSubmitHandle }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "提示",
            visible: _vm.closeDialogVisible,
            "close-on-click-modal": false,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.closeDialogVisible = $event
            }
          }
        },
        [
          _c("span", [_vm._v("确定关闭？")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.closeDialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmCloseHandle }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "tag" }, [
      _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
      _vm._v("生产类型：")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "tag" }, [
      _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
      _vm._v("用途：")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "tag" }, [
      _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
      _vm._v("销售订单编号：")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }